import { render, staticRenderFns } from "./LDTrend.vue?vue&type=template&id=65eaa28c&scoped=true&"
import script from "./LDTrend.vue?vue&type=script&lang=js&"
export * from "./LDTrend.vue?vue&type=script&lang=js&"
import style0 from "./LDTrend.vue?vue&type=style&index=0&id=65eaa28c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65eaa28c",
  null
  
)

export default component.exports