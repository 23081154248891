<template>
    <div>
        <table class="expand_table">
            <tr v-show="row.correct_time_date_content">
                <td class="expand-td-left">整改时限: </td>
                <td class="expand-td-right">{{ row.correct_time_date_content }}</td>
            </tr>
            <tr v-show="row.monitor_operation">
                <td class="expand-td-left">防范监控措施: </td>
                <td class="expand-td-right">{{ row.monitor_operation }}</td>
            </tr>
            <tr v-show="row.correct_date">
                <td class="expand-td-left">完成治理日期: </td>
                <td class="expand-td-right">{{ row.correct_date }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default {
        name: "latentDangerListExpand",
        props: {
            row: Object
        }
    }
</script>
<style scoped>
    .wrapper{
        text-align: center;
    }
    .expand_table {
        margin: 0 auto;
        width:80%;
        border-collapse: collapse;
    }
    .expand-td-left{
        padding: 0 20px;
        width:150px;
        text-align: right;
        background-color: inherit;
        border: none;
        color:#888;
    }
    .expand-td-right{
        background-color: inherit;
        border: none;
        font-size: 14px;
    }
</style>