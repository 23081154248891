<template>
  <data-view
    :columnsFormat="columnsFormat"
    :askData="op_name"
    @refreshDo="chartRefresh"
  >
    <template v-slot:default="dv_props">
      <el-tab-pane>
        <span slot="label" class="pane_label">
          <Icon type="ios-pie" />占比图
        </span>
        <div class="echarts-wrapper">
          <div class="single-chart-wrapper">
            <ld-stat
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></ld-stat>
          </div>
          <!-- <div class="single-chart-wrapper">
            <ld-level
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></ld-level>
          </div>
          <div class="single-chart-wrapper">
            <ld-catagory
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></ld-catagory>
          </div> -->
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label" class="pane_label">
          <Icon type="md-podium" />统计图
        </span>
        <div class="single-chart-wrapper">
          <ld-line
            :choosedOptions="dv_props.choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></ld-line>
        </div>
        <!-- <div class="single-chart-wrapper">
          <ldmajor-system
            :choosedOptions="dv_props.choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></ldmajor-system>
        </div> -->
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label" class="pane_label">
          <Icon type="ios-trending-up" />趋势图
        </span>
        <div class="single-chart-wrapper">
          <ld-trend
            :choosedOptions="dv_props.choosedOptions"
            :drawAgain="updateCount"
            :esize="echartSize"
          ></ld-trend>
        </div>
      </el-tab-pane>
      <latent-danger-list-expand v-if="false"></latent-danger-list-expand>
    </template>
  </data-view>
</template>
<script>
import dataView from "@/components/Common/DataView.vue";
import latentDangerListExpand from "./LatentDangerListExpand.vue";
// import ldLevel from "./Charts/LDLevel.vue";
import ldLine from "./Charts/LDLine.vue";
// import ldmajorSystem from "./Charts/LDMajorSystem.vue";
// import ldCatagory from "./Charts/LDCatagory.vue";
import ldTrend from "./Charts/LDTrend.vue";
import ldStat from "./Charts/LDStat.vue";

export default {
  name: "latentDangerList",
  props: [],
  components: {
    dataView,
    latentDangerListExpand,
    // ldLevel,
    ldLine,
    // ldmajorSystem,
    // ldCatagory,
    ldTrend,
    ldStat,
  },
  data() {
    return {
      op_name: this.$options.name,
      updateCount: 1,
      echartSize: { height: "700px", width: "1000px" },
      columnsFormat: [
        {
          type: "expand",
          width: 30,
          render: (h, params) => {
            console.log(params);
            return h(latentDangerListExpand, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          title: "线路",
          key: "line_name",
          width: 75,
          align: "center",
        },
        {
          title: "责任单位",
          key: "res_department",
          width: 130,
          align: "center",
        },
        {
          title: "排查时间",
          key: "found_date",
          width: 100,
          align: "center",
        },
        // {
        //   title: "专业",
        //   key: "major_system",
        //   width: 90,
        //   tooltip: "true",
        //   align: "center",
        // },
        {
          title: "隐患情况",
          key: "description",
          tooltip: "true",
          align: "center",
          minWidth: 120,
        },
        // {
        //   title: "隐患等级",
        //   key: "danger_grade",
        //   width: 85,
        //   align: "center",
        // },
        // {
        //   title: "隐患类别",
        //   key: "catagory",
        //   width: 110,
        //   align: "center",
        // },
        {
          title: "整改措施",
          key: "correct_operation",
          align: "center",
          tooltip: "true",
          minWidth: 120,
        },
        // {
        //                 title: '整改时限',
        //                 key: 'correct_time_date_content',
        //                 width: 100,
        //                 align: "center",
        //             },
        {
          title: "治理情况",
          width: 140,
          tooltip: "true",
          align: "center",
          key: "correct_condition",
        },
        {
          title: "状态",
          width: 80,
          align: "center",
          tooltip: "true",
          key: "correct_stat",
        },
        {
          title: "整改日期",
          key: "correct_date",
          width: 100,
          align: "center",
        },
        {
          title: "备注",
          width: 80,
          tooltip: "true",
          align: "center",
          key: "remark",
        },
      ],
    };
  },
  methods: {
    chartRefresh() {
      this.updateCount++;
    },
  },
};
</script>
<style scoped>
.single-chart-wrapper {
  margin-top: 10px;
}
</style>